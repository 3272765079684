
// client/src/theme.ts
import { TextInput, Input, createTheme, MantineColorsTuple, MantineGradient } from '@mantine/core';
import classes from './Theme.module.css';
// export const theme: MantineThemeOverride = {
//   // Define your breakpoints here
//   breakpoints: {
//     xs: 480,
//     sm: 768,
//     md: 992,
//     lg: 1200,
//   },
//   // Add other theme configurations as needed
// };

const brightPink: MantineColorsTuple = [
  '#F0BBDD',
  '#ED9BCF',
  '#EC7CC3',
  '#ED5DB8',
  '#F13EAF',
  '#F71FA7',
  '#FF00A1',
  '#E00890',
  '#C50E82',
  '#AD1374'
];

const myColors: MantineColorsTuple = [
  "#e5fbfe",
  "#d9eff3",
  "#b8dce2",
  "#94c9d0",
  "#75b9c1",
  "#61aeb8",
  "#54a9b4",
  "#42949f",
  "#33848e",
  "#1a737d"
];

const myRedPalette: MantineColorsTuple = [
  "#ffebee",
  "#ffcdd2",
  "#ef9a9a",
  "#e57373",
  "#ef5350",
  "#f44336",
  "#e53935",
  "#d32f2f",
  "#c62828",
  "#b71c1c"
];

const blueAccent: MantineColorsTuple = [
  '#eef3ff',
  '#dce4f5',
  '#b9c7e2',
  '#94a8d0',
  '#748dc1',
  '#5f7cb8',
  '#5474b4',
  '#44639f',
  '#39588f',
  '#2d4b81'
];

const purpleGradient: MantineGradient = {
  from: '#8e2de2',
  to: '#4a00e0',
  deg: 45,
};



export const theme = createTheme({
  /** Put your mantine theme override here */

  colors: {
    // Override the primary color with teal shades
    myColors, 
    myRedPalette,
    'blue-accent': blueAccent,
    'bright-pink': brightPink,
  },
  components: {
    Input: Input.extend({ classNames: classes }),
    TextInput: TextInput.extend({ classNames: classes }),
  },
  defaultGradient: purpleGradient,
  primaryColor: 'myColors',
  primaryShade: 7,
  scale: 1.2

});

