import { useEffect } from 'react';
import { perf, trace } from './../firebase/firebaseInit';
import { useLocation } from 'react-router-dom';


const usePerformanceTrace = () => {
  const location = useLocation();
  useEffect(() => {
    const t = trace(perf,'RouteChangeTracker');
    t.putAttribute("pathname", location.pathname);
    t.start();
    console.debug('RouteChangeTracker Run. Tracing location:', location.pathname);

    return () => {
      t.stop();
      console.debug('RouteChangeTracker Cleanup. Stopped tracing location:', location.pathname);
    };
  }, [location]);
};

export default usePerformanceTrace;
