// Import necessary components and hooks from react-router-dom
import { Link, Route, Routes, useLocation } from 'react-router-dom';
import { AppShell, MantineProvider, NavLink, Title } from '@mantine/core';
import '@mantine/core/styles.css';
import '@mantine/notifications/styles.css';
import { HomePage } from './pages/HomePage';
import { LoadGamesPage } from './pages/LoadGamesPage';
import GamePage from './pages/GamePage';
import { theme } from './Theme';
import DayNightToggle from './components/DayNightToggle';
import { Notifications } from '@mantine/notifications';
import usePerformanceTrace from './hooks/usePerformanceTrace';

function App() {
  const location = useLocation(); // Use the useLocation hook to get the current location
  usePerformanceTrace(); // Use the custom hook to track performance

  const navLinkData = [
    { to: '/', label: 'Home' },
    { to: '/load-games', label: 'Load Games' },
    // { to: '/game', label: 'Game' }
  ]

  const navLinkItems = navLinkData.map((item) => {
    return (
      <NavLink
        key={item.label}
        component={Link}
        to={item.to}
        label={item.label}
        active={location.pathname === item.to} // Set active based on current path
        // style={{ marginRight: '20px' }} // Adjust spacing as needed
        variant='filled'
        m={0}

      />
    )
  });

  return (
    <>
      <MantineProvider theme={theme} defaultColorScheme='auto'>
        <Notifications />
        <AppShell
          header={{ height: { base: 70, sm: 70, lg: 76 } }}
          // header={{ height: { default: 70, xs: 70, sm: 70, md: 70, lg: 70 } }}
          // padding={{ default: 'sm', sm: 'sm' }}
          padding={{ base: 10, sm: 15, lg: 15 }}

        >
          <AppShell.Header>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
              <Title order={3} textWrap='balance' >Better Cryptograms</Title>
              <nav style={{ display: 'flex', alignItems: 'center', maxWidth: '100vw'}}>
                {navLinkItems}
                <DayNightToggle />
              </nav>
            </div>
          </AppShell.Header>


          <AppShell.Main>
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/load-games" element={<LoadGamesPage />} />
              <Route path="/gamepage" element={<GamePage />} />
            </Routes>
          </AppShell.Main>
        </AppShell>

      </MantineProvider>
    </>
  )
}

export default App
